import styled from 'styled-components';

export const StyledTag = styled.div<{
  backgroundColor: string;
  disabled?: boolean;
  selectable?: boolean;
  suggested?: boolean;
  borderColor?: string;
  maxWidth?: number;
}>`
  width: fit-content;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  padding: 2px 6px;
  min-height: 18px;

  background-color: ${(props) => props.backgroundColor};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;

  font-size: ${({ theme }) => theme.typology.font.size.xs};
  line-height: ${({ theme }) => theme.typology.lineHeight.small};
  color: ${({ theme }) => theme.colors.neutral.gray850};
  position: relative;
  user-select: none;

  border: 1px solid ${(props) => props.backgroundColor}};

  ${(props) => (props.disabled ? 'opacity: 0.5;' : '')}
  ${(props) =>
    props.selectable &&
    `cursor: pointer;
    &:hover {
      border: 1px solid ${props.borderColor};
    }
  `}

  ${(props) =>
    props.suggested &&
    `
    border: 1px dashed ${props.borderColor};
  `}
`;

export const StyledLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 2px;
`;

export const StyledCross = styled.div`
  width: 14px;
  height: 14px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background: rgba(45, 47, 48, 0.08);
  }
`;
