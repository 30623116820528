export type CrossProps = {
  size?: number;
};

export const Cross = ({ size = 16 }: CrossProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.79095 4.83502L12.1143 11.1584"
      stroke="#2D2F30"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.79095 11.1584L12.1143 4.83502"
      stroke="#2D2F30"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
