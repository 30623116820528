import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { humanizeDate } from 'utils/time';

type HTimelineProps = {
  items: { id: string; label: string; date: Date; icon?: JSX.Element }[];
};

export const HTimeline = ({ items }: HTimelineProps) => {
  return (
    <Timeline
      sx={{
        '& .MuiTimelineItem-root:before': {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {items
        .sort((a, b) => (a.date > b.date ? 1 : -1))
        .map((item, i) => (
          <TimelineItem key={item.id}>
            <TimelineSeparator>
              <TimelineDot style={{ width: '16px', height: '16px' }} />
              {i < items.length - 1 ? <TimelineConnector /> : null}
            </TimelineSeparator>
            <TimelineContent>
              {item.label} - {humanizeDate(item.date)}
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};
